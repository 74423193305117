export const chordCharts = [
  {
    section: 'Major Chords',
    description: '',
    chords: [
      {
        name: 'Maj',
        chord: [
          [1, 2, 1],
          [2, 2, 1],
          [3, 3, 2],
          [4, 4, 3],
          [5, 4, 4],
          [6, 2, 1],
        ],
        position: 0,
        barres: [
          { fromString: 6, toString: 1, fret: 2 },
        ],
        tuning: ['R', '5', 'R', '3', '5', 'R']
      },
      {
        name: 'Maj',
        chord: [
          [1, 'x'],
          [2, 4],
          [3, 4],
          [4, 4],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [
          { fromString: 4, toString: 2, fret: 4 },
        ],
        tuning: ['x', 'R', '5', 'R', '3', 'x']
      },
      {
        name: 'Maj',
        chord: [
          [1, 'x'],
          [2, 4, 4],
          [3, 3, 3],
          [4, 1, 2],
          [5, 'x'],
          [6, 3, 1],
        ],
        position: 1,
        barres: [],
        tuning: ['3', 'x', 'R', '5', 'R', 'x']
      },
      {
        name: 'Maj6',
        chord: [
          [1, 'x'],
          [2, 2, 3],
          [3, 3, 4],
          [4, 1, 2],
          [5, 'x'],
          [6, 2, 1],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', '6', '3', '5', 'x']
      },
      {
        name: 'Maj6',
        chord: [
          [1, 'x'],
          [2, 4, 3],
          [3, 3, 1],
          [4, 4, 2],
          [5, 4, 2],
          [6, 2, 'T'],
        ],
        position: 1,
        barres: [
          { fromString: 5, toString: 4, fret: 4 },
        ],
        tuning: ['R', '5', 'R', '3', '6', 'x']
      },
      {
        name: 'Maj6',
        chord: [
          [1, 2, 3],
          [2, 'x'],
          [3, 1, 1],
          [4, 1, 1],
          [5, 2, 2],
          [6, 'x'],
        ],
        position: 1,
        barres: [
          { fromString: 4, toString: 3, fret: 1 },
        ],
        tuning: ['x', 'R', '3', '6', 'x', '5']
      },
      {
        name: 'Maj7',
        chord: [
          [1, 'x'],
          [2, 2, 2],
          [3, 3, 4],
          [4, 3, 3],
          [5, 'x'],
          [6, 2, 1],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', '7', '3', '5', 'x']
      },
      {
        name: 'Maj7',
        chord: [
          [1, 'x'],
          [2, 4, 4],
          [3, 3, 2],
          [4, 4, 3],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', '5', '7', '3', 'x']
      },
      {
        name: 'Maj9',
        chord: [
          [1, 'x'],
          [2, 2, 2],
          [3, 1, 1],
          [4, 3, 3],
          [5, 1, 1],
          [6, 2, 'R'],
        ],
        position: 1,
        barres: [
          { fromString: 5, toString: 3, fret: 1 },
        ],
        tuning: ['x', '3', '7', '9', '5', 'x']
      },
      {
        name: 'Maj9',
        chord: [
          [1, 'x'],
          [2, 2, 3],
          [3, 3, 4],
          [4, 1, 1],
          [5, 2, 2],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', '3', '7', '9', 'x']
      },
      {
        name: 'Maj69',
        chord: [
          [1, 'x'],
          [2, 2, 3],
          [3, 1, 1],
          [4, 1, 1],
          [5, 'x'],
          [6, 2, 2],
        ],
        position: 1,
        barres: [
          { fromString: 4, toString: 3, fret: 1 },
        ],
        tuning: ['R', 'x', '6', '9', '5', 'x']
      },
      {
        name: 'Maj69',
        chord: [
          [1, 4, 3],
          [2, 4, 3],
          [3, 3, 1],
          [4, 4, 2],
          [5, 4, 2],
          [6, 2, 'T'],
        ],
        position: 1,
        barres: [
          { fromString: 5, toString: 4, fret: 4 },
          { fromString: 2, toString: 1, fret: 4 },
        ],
        tuning: ['R', '5', 'R', '3', '6', '9']
      },
      {
        name: 'Maj69',
        chord: [
          [1, 2, 4],
          [2, 2, 3],
          [3, 1, 1],
          [4, 1, 1],
          [5, 2, 2],
          [6, 'x'],
        ],
        position: 1,
        barres: [
          { fromString: 4, toString: 3, fret: 1 },
        ],
        tuning: ['x', 'R', '3', '6', '9', '5']
      }
    ]
  },
  {
    section: 'Minor Chords',
    description: '',
    chords: [
      {
        name: 'm',
        chord: [
          [1, 2, 1],
          [2, 2, 1],
          [3, 2, 1],
          [4, 4, 3],
          [5, 4, 4],
          [6, 2, 1],
        ],
        position: 1,
        barres: [
          { fromString: 6, toString: 1, fret: 2 },
        ],
        tuning: ['R', '5', 'R', 'b3', '5', 'R']
      },
      {
        name: 'm',
        chord: [
          [1, 2, 1],
          [2, 3, 2],
          [3, 4, 4],
          [4, 4, 3],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [
          { fromString: 5, toString: 1, fret: 2 },
        ],
        tuning: ['x', 'R', '5', 'R', 'b3', '5']
      },
      {
        name: 'm6',
        chord: [
          [1, 'x'],
          [2, 2, 4],
          [3, 2, 3],
          [4, 1, 1],
          [5, 'x'],
          [6, 2, 2],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', '6', 'b3', '5', 'x']
      },
      {
        name: 'm6',
        chord: [
          [1, 'x'],
          [2, 4, 3],
          [3, 2, 1],
          [4, 4, 2],
          [5, 4, 2],
          [6, 2, 'T'],
        ],
        position: 1,
        barres: [{
          fromString: 5, toString: 4, fret: 4
        }],
        tuning: ['R', '5', 'R', 'b3', '6', 'x']
      },
      {
        name: 'm6',
        chord: [
          [1, 'x'],
          [2, 2, 4],
          [3, 1, 2],
          [4, 2, 3],
          [5, 1, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', '6', 'b3', '5', 'R', 'x']
      },
      {
        name: 'm9',
        chord: [
          [1, 4, 4],
          [2, 2, 3],
          [3, 2, 3],
          [4, 2, 3],
          [5, 'x'],
          [6, 2, 2],
        ],
        position: 1,
        barres: [
          { fromString: 4, toString: 2, fret: 2 }
        ],
        tuning: ['R', 'x', 'b7', 'b3', '5', '9']
      },
      {
        name: 'm9',
        chord: [
          [1, 'x'],
          [2, 3, 4],
          [3, 3, 3],
          [4, 1, 1],
          [5, 'x',],
          [6, 3, 2],
        ],
        position: 1,
        barres: [],
        tuning: ['5', 'x', 'b3', 'b7', '9', 'x']
      },
      {
        name: 'm7',
        chord: [
          [1, 'x'],
          [2, 2, 3],
          [3, 2, 3],
          [4, 2, 3],
          [5, 'x',],
          [6, 2, 2],
        ],
        position: 1,
        barres: [{
          fromString: 4, toString: 2, fret: 2
        }],
        tuning: ['R', 'x', 'b7', 'b3', '5', 'x']
      },
      {
        name: 'm7',
        chord: [
          [1, 'x'],
          [2, 4, 4],
          [3, 3, 3],
          [4, 1, 1],
          [5, 3, 'R'],
          [6, 3, 2],
        ],
        position: 1,
        barres: [],
        tuning: ['5', 'x', 'b3', 'b7', 'b3', 'x']
      },
      {
        name: 'm7',
        chord: [
          [1, 2, 3],
          [2, 3, 4],
          [3, 2, 2],
          [4, 'x'],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', 'x', 'b7', 'b3', '5']
      },
      {
        name: 'mM7',
        chord: [
          [1, 2, 1],
          [2, 2, 1],
          [3, 2, 1],
          [4, 3, 2],
          [5, 4, 3],
          [6, 2, 1],
        ],
        position: 1,
        barres: [
          { fromString: 6, toString: 1, fret: 2 }
        ],
        tuning: ['R', '5', '7', 'b3', '5', 'R']
      },
      {
        name: 'mM7',
        chord: [
          [1, 2, 2],
          [2, 3, 4],
          [3, 3, 3],
          [4, 'x'],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', 'x', '7', 'b3', '5']
      },
      {
        name: 'm7b5',
        chord: [
          [1, 'x'],
          [2, 1, 1],
          [3, 2, 4],
          [4, 2, 3],
          [5, 'x'],
          [6, 2, 2],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', 'b7', 'b3', '5', 'x']
      },
      {
        name: 'm7b5',
        chord: [
          [1, 'x'],
          [2, 3, 4],
          [3, 2, 2],
          [4, 3, 2],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', 'b5', 'b7', 'b3', 'x']
      }
    ]
  },
  {
    section: 'Dominant Chords',
    description: '',
    chords: [
      {
        name: '7',
        chord: [
          [1, 'x'],
          [2, 2, 3],
          [3, 3, 4],
          [4, 2, 2],
          [5, 'x'],
          [6, 2, 1],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', 'b7', '3', '5', 'x']
      },
      {
        name: '7b9',
        chord: [
          [1, 'x'],
          [2, 2, 1],
          [3, 3, 3],
          [4, 2, 1],
          [5, 'x'],
          [6, 3, 2],
        ],
        position: 1,
        barres: [
          { fromString: 4, toString: 2, fret: 2 }
        ],
        tuning: ['R', 'x', 'b7', '3', '5', 'x']
      },
      {
        name: '79',
        chord: [
          [1, 'x'],
          [2, 2, 4],
          [3, 1, 2],
          [4, 2, 3],
          [5, 1, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', '3', 'b7', '9', '5', 'x']
      },
      {
        name: '7#9',
        chord: [
          [1, 'x' ],
          [2, 2, 3],
          [3, 2, 3],
          [4, 2, 3],
          [5, 1, 1],
          [6, 2, 1],
        ],
        position: 1,
        barres: [
          { fromString: 4, toString: 2, fret: 2 }
        ],
        tuning: ['x', '3', 'b7', '9', '5', 'x']
      },
      {
        name: '7b5',
        chord: [
          [1, 'x' ],
          [2, 1, 1],
          [3, 3, 4],
          [4, 2, 3],
          [5, 'x'],
          [6, 2, 2],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', 'b7', '3', 'b5', 'x']
      },
      {
        name: '7#5',
        chord: [
          [1, 'x' ],
          [2, 3, 4],
          [3, 3, 3],
          [4, 2, 2],
          [5, 'x'],
          [6, 2, 1],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', 'b7', '3', '#5', 'x']
      },
      {
        name: '13',
        chord: [
          [1, 'x' ],
          [2, 4, 4],
          [3, 3, 3],
          [4, 2, 2],
          [5, 'x'],
          [6, 2, 1],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', 'b7', '3', '13', 'x']
      },
      {
        name: '7',
        chord: [
          [1, 'x' ],
          [2, 2, 1],
          [3, 4, 4],
          [4, 3, 2],
          [5, 4, 'R'],
          [6, 4, 3],
        ],
        position: 1,
        barres: [],
        tuning: ['5', 'x', '3', 'b7', 'R', 'x']
      },
      {
        name: '7b9',
        chord: [
          [1, 'x' ],
          [2, 1, 1],
          [3, 2, 3],
          [4, 1, 1],
          [5, 2, 'R'],
          [6, 2, 2],
        ],
        position: 1,
        barres: [
          {
            fromString: 4, toString: 2, fret: 1
          }
        ],
        tuning: ['5', 'x', '3', 'b7', 'b9', 'x']
      },
      {
        name: '79',
        chord: [
          [1, 2, 3],
          [2, 2, 3],
          [3, 2, 3],
          [4, 1, 2],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [
          {
            fromString: 3, toString: 1, fret: 2
          }
        ],
        tuning: ['x', 'R', '3', 'b7', '9', '5']
      },
      {
        name: '7#9',
        chord: [
          [1, 'x'],
          [2, 3, 4],
          [3, 2, 3],
          [4, 1, 1],
          [5, 2, 2],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', '3', 'b7', '#9', 'x']
      },
      {
        name: '7b5',
        chord: [
          [1, 'x'],
          [2, 4, 4],
          [3, 2, 2],
          [4, 3, 3],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', 'b5', 'b7', '3', 'x']
      },
      {
        name: '7#5',
        chord: [
          [1, 3, 4],
          [2, 'x'],
          [3, 2, 3],
          [4, 1, 1],
          [5, 2, 2],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['x', 'R', '3', 'b7', 'x', '#5']
      },
      {
        name: '13',
        chord: [
          [1, 4, 4],
          [2, 4, 4],
          [3, 2, 2],
          [4, 'x'],
          [5, 2, 1],
          [6, 'x'],
        ],
        position: 1,
        barres: [
          {fromString: 2, toString: 1, fret: 4}
        ],
        tuning: ['x', 'R', 'x', 'b7', '3', '13']
      }
    ]
  },
  {
    section: 'Diminished Chords',
    description: '',
    chords: [
      {
        name: 'dim7',
        chord: [
          [1, 'x'],
          [2, 1, 1],
          [3, 2, 3],
          [4, 1, 1],
          [5, 'x'],
          [6, 2, 2],
        ],
        position: 1,
        barres: [
          {fromString: 4, toString: 2, fret: 1}
        ],
        tuning: ['R', 'x', 'bb7', 'b3', 'b5', 'x']
      },
      {
        name: 'dim7',
        chord: [
          [1, 'x'],
          [2, 3, 4],
          [3, 1, 1],
          [4, 3, 3],
          [5, 2, 2],
          [6, 'x'],
        ],
        position: 1,
        barres: [],
        tuning: ['R', 'x', 'b5', 'bb7', 'b3', 'x']
      }
    ]
  }
];