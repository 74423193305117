export const chordOptions = {
  // Customizations (all optional, defaults shown)
  width: 180, // canvas width
  height: 250, // canvas height
  circleRadius: 5, // circle radius (width / 20 by default)

  numStrings: 6, // number of strings (e.g., 4 for bass)
  numFrets: 4, // number of frets (e.g., 7 for stretch chords)
  showTuning: true, // show tuning keys

  defaultColor: '#4B5563', // default color
  bgColor: '#FFF', // background color
  strokeColor: '#4B5563', // stroke color (overrides defaultColor)
  textColor: '#4B5563', // text color (overrides defaultColor)
  stringColor: '#4B5563', // string color (overrides defaultColor)
  fretColor: '#4B5563', // fret color (overrides defaultColor)
  labelColor: '#fff', // label color (overrides defaultColor)

  fretWidth: 1, // fret width
  stringWidth: 1, // string width

  // fontFamily,
  // fontSize,
  // fontWeight,
  // fontStyle, // font settings
  // labelWeight // weight of label font
}