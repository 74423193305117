import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import { ChordBox } from 'vexchords';
import { chordOptions } from './chord-options';
import { chordCharts } from './chords-charts';

const chords = [];

function createChordElement(chordStruct) {
  const chordbox = $('<div>').addClass('chord border pb-6');
  const chordcanvas = $('<div>');
  const chordname = $('<div>').addClass('chordname text-gray-600');

  chordbox.append(chordcanvas);
  chordbox.append(chordname);
  chordname.append(chordStruct.name);

  chords.push({
    el: chordcanvas[0],
    struct: chordStruct
  });

  return chordbox;
}

function createSectionElement(sectionStruct) {
  const section = $('<div>').addClass('section mb-12');
  const sectionTitle = $('<div>').addClass('title border border-b-0 border-l-0 border-r-0 border-t-1 border-green-600 mb-6 sticky top-0');
  const sectionTitleH1 = $('<h1>').addClass('title__headline inline-block py-2 px-4 bg-green-600 text-white w-auto font-bold');
  const sectionDesc = $('<div>').addClass('description');
  const sectionGrid = $('<div>').addClass('section__grid');
  
  section.append(sectionTitle);
  section.append(sectionDesc);
  section.append(sectionGrid);
  sectionTitle.append(sectionTitleH1);
  sectionTitleH1.append(sectionStruct.section);
  sectionDesc.append(sectionStruct.description);

  return section;
}

function init() {
  var container = $('#container');

  for (var i = 0; i < chordCharts.length; ++i) {
    var section_struct = chordCharts[i];
    var section = createSectionElement(section_struct);
    const sectionGrid = section.find('.section__grid');

    for (var j = 0; j < section_struct.chords.length; ++j) {
      sectionGrid.append(createChordElement(section_struct.chords[j]));
    }

    container.append(section);
  }

  chords.forEach(chord => {
    new ChordBox(chord.el, chordOptions).draw(chord.struct);
  });
}

init();